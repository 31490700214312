<template>
  <b-container fluid>
    <b-col class="mt-3 pr-0">
      <!-- Currency & Hourly rate block -->
      <b-col
        cols="11"
        class="d-inline-flex pl-0"
      >
        <b-col
          class="pl-0"
        >
          <b-col class="h8 pl-0 mb-2">
            Rate currency
            <span class="text-primary">*</span>
          </b-col>
          <b-col class="pl-0">
            <b-form-select
              v-model="currency"
              class="h9"
              :options="currencyOptions"
            />
          </b-col>
        </b-col>
        <b-col class="pl-0">
          <b-col class="h8 pl-0 mb-2">
            Contingency Fee
            <span class="text-primary">*</span>
          </b-col>
          <b-col class="pl-0 d-inline-flex">
            <b-form-input
              v-model="contingency_fee"
              :formatter="formatNumber"
              class="h9"
              placeholder="% of the annual salary"
              number
            />
            <label class="radio-btns-text ml-4 mt-2 h9">
              of
            </label>
          </b-col>
        </b-col>
        <b-col class="pl-0">
          <b-col class="h8 pl-0 mb-2">
            Annual Salary
            <span class="text-primary">*</span>
            <i
              v-b-tooltip.hover
              class="ri-question-line ml-2 ri-1x"
              title="Salary amount is only an estimate. It’s used for calculation purposes and will be displayed as an example to the client. Precise salary is known once the candidate is hired"
            />
          </b-col>
          <b-col class="pl-0">
            <b-form-input
              v-model="annual_salary"
              class="h9"
              placeholder="Specify the annual salary (.e.g 50,000)"
              number
            />
          </b-col>
        </b-col>
      </b-col>
    </b-col>
    <hr>
    <b-col
      class="pl-0"
      cols="8"
    >
      <b-col class="d-inline-flex h9 pl-0">
        <b-col class="text-left">
          <b class="mr-1">Total fee</b>
          <i
            v-b-tooltip.hover
            class="ri-question-line"
            title="Total fee the client will see on your proposal. Includes your fee and Relancer Service Fee"
          />
        </b-col>
        <b-col class="text-left pr-0">
          {{ currencyChanger }} {{ yourFee }}
        </b-col>
      </b-col>
      <b-col class="d-inline-flex h9 pl-0 mt-3">
        <b-col
          class="text-left"
        >
          <b class="mr-1">Relancer Service Fee</b>
          <b-btn
            v-b-modal.explanation
            variant="text"
          >
            - Explain this
          </b-btn>
        </b-col>
        <b-col class="text-left pr-0">
          {{ currencyChanger }} {{ relancerTakes }}
        </b-col>
      </b-col>
      <b-modal
        id="explanation"
        ok-only
        ok-title="Close"
        ok-variant="primary"
        centered
      >
        <b-card
          header="Relancer service fees"
          class="h6"
        >
          <b-row>
            <b-col class="h9 text-left">
              Relancer charges Recruiters a sliding Service Fee based on the <b>total amount billed</b> to a single client. The higher the total amount, the lower the percentage you pay to Relancer.
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-3">
            <b-col class="h9 text-left">
              <b>Total billings with the client</b>
            </b-col>
            <b-col class="h9 text-left">
              <b>Fee %</b>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="h9 text-left d-inline-flex p-0">
              <b-col>First 10,000€</b-col>
              <b-col>15%</b-col>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col class="h9 text-left d-inline-flex p-0">
              <b-col>Between 10,000 - 20,000€</b-col>
              <b-col>10%</b-col>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col class="h9 text-left d-inline-flex p-0">
              <b-col>After 20,000€</b-col>
              <b-col>5%</b-col>
            </b-col>
          </b-row>
        </b-card>
      </b-modal>
      <b-col class="d-inline-flex h9 pl-0 mt-3">
        <b-col class="text-left">
          <b class="mr-1">You get</b>
          <i
            v-b-tooltip.hover
            class="ri-question-line"
            title="The estimated amount you’ll receive after Relancer service fees"
          />
        </b-col>
        <b-col class="text-left pr-0">
          <b>{{ currencyChanger }} {{ youGet }} </b>
        </b-col>
      </b-col>
    </b-col>
  </b-container>
</template>

<script>
import { vuexMapper } from '@/helpers/vuexMapper'

export default {
  name: 'ContingencyCalc',
  data () {
    return {
      currencyOptions: [
        { value: null, text: 'Choose currency' },
        { value: '€', text: '€' },
        { value: '$', text: '$' },
        { value: '£', text: '£' },
      ],
      relancerFee: 15,
      relancerFee2: 10,
      relancerFee3: 5,
    }
  },
  computed: {
    yourFee () {
      let userFee = 0

      if (this.contingency_fee && this.annual_salary) {
        userFee = this.annual_salary * this.contingency_fee / 100
      }
      return userFee.toFixed(2)
    },
    youGet () {
      let totalPrice = 0
      let gapPrice1 = 0
      let gapPrice2 = 0
      let gapPrice3 = 0
      let gapPrice1Fee = 0
      let gapPrice2Fee = 0
      let gapPrice3Fee = 0

      if (this.yourFee <= 10000) {
        // Dividing the fee proposal with Relancer's 15% fee
        totalPrice = this.yourFee - (this.relancerFee * this.yourFee) / 100
      } else if (this.yourFee > 10000 && this.yourFee <= 20000) {
        // Getting the first 5k out of total amount of fee (max 10k)
        gapPrice1 = this.yourFee - 10000
        // Getting the remainder of the total amount fee - first 5k
        gapPrice2 = this.yourFee - gapPrice1
        // Getting the first 5k relancer fee 5k / 15%
        gapPrice1Fee = (this.relancerFee2 * gapPrice1) / 100
        // Getting the reminder fee = reminder amount / 10%
        gapPrice2Fee = (this.relancerFee * gapPrice2) / 100
        totalPrice = this.yourFee - (gapPrice1Fee + gapPrice2Fee)
      } else if (this.yourFee > 20000) {
        // Getting the reminder if total amount is bigger than 10k
        gapPrice3 = (this.yourFee - 20000)
        // We already know that if total amount is bigger than 10k then the first relancer fee is 750€
        gapPrice1Fee = 1500
        // Second relancer fee is 500€
        gapPrice2Fee = 1000
        // Getting the last reminder relancer fee = reminder amount / 5%
        gapPrice3Fee = (this.relancerFee3 * gapPrice3) / 100
        totalPrice = this.yourFee - (gapPrice1Fee + gapPrice2Fee + gapPrice3Fee)
      }
      return totalPrice.toFixed(2)
    },
    relancerTakes () {
      return this.yourFee - this.youGet
    },
    currencyChanger () {
      let currency = ''
      if (this.currency === 'EUR') {
        currency = '€'
      }
      if (this.currency === 'USD') {
        currency = '$'
      }
      if (this.currency === 'GBP') {
        currency = '£'
      }
      return currency
    },
    ...vuexMapper({
      fields: [
        'currency',
        'contingency_fee',
        'annual_salary',
      ],
      base: 'proposal',
      nestedObject: 'proposalForm',
      mutation: 'UPDATE_STORE',
    }),
  },
  methods: {
    formatNumber (e) {
      return String(e).substring(0, 3)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
