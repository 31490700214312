<template>
  <b-container fluid>
    <b-col
      class="mt-3"
    >
      <!-- Currency & Hourly rate block -->
      <b-col
        cols="6"
        class="d-inline-flex pl-0"
      >
        <b-col class="pl-0">
          <b-col class="h8 pl-0 mb-2">
            Rate currency
            <span class="text-primary">*</span>
          </b-col>
          <b-col class="pl-0">
            <b-form-select
              v-model="currency"
              class="h9"
              :options="currencyOptions"
            />
          </b-col>
        </b-col>
        <b-col class="pl-0">
          <b-col class="h8 pl-0 mb-2">
            Hourly rate
            <span class="text-primary">*</span>
          </b-col>
          <b-col class="pl-0">
            <b-form-input
              v-model="hourly_rate"
              class="h9"
              placeholder="E.g. 50"
              number
            />
          </b-col>
        </b-col>
      </b-col>
      <!-- Additonal terms block -->
      <!-- <b-col
        cols="10"
        class="pl-0 mt-4"
      >
        <b-col class="pl-0">
          <b-col class="h8 pl-0 mb-1">
            Additional terms
          </b-col>
          <b-col class="pl-0 h9">
            Are you going to charge a fixed rate plus any additonal term?
          </b-col>
        </b-col>
        <b-col class="pl-0 mt-2">
          <b-form-group>
            <b-form-radio
              v-model="additional_terms"
              value="no additional charges"
              class="radio-btns-text"
            >
              <label class="radio-btns-text h9">
                No additional charges
              </label>
            </b-form-radio>
            <b-form-radio
              v-model="additional_terms"
              value="fixed fee bonus"
              class="radio-btns-text"
            >
              <label class="radio-btns-text h9">
                Hourly fee + bonus (fixed fee)
              </label>
              <b-form-input
                v-if="additional_terms === 'fixed fee bonus'"
                v-model="fixedFeeSum"
                class="h9 mt-1"
                placeholder="E.g. 1000"
                number
              />
            </b-form-radio>
            <b-form-radio
              v-model="additional_terms"
              value="contingency bonus"
              class="radio-btns-text"
            >
              <label class="radio-btns-text h9">
                Hourly fee + Contingency (% of the annual salary)
              </label>
              <b-col
                v-if="additional_terms === 'contingency bonus'"
                class="pl-0 d-inline-flex mt-1"
              >
                <b-form-input
                  v-model="annualSalaryPercentage"
                  :formatter="formatNumber"
                  class="h9"
                  placeholder="% of the annual salary"
                  number
                />
                <label class="radio-btns-text ml-3 mr-3 mt-2 h9">
                  of
                </label>
                <b-form-input
                  v-model="annualSalary"
                  class="h9"
                  placeholder="Specify the annual salary (.e.g 50,000)"
                  number
                />
              </b-col>
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-col> -->
      <!-- Payment terms block -->
      <!-- <b-col
        v-if="additional_terms && additional_terms !== 'no additional charges'"
        class="pl-0 mt-4"
      >
        <b-col class="pl-0">
          <b-col class="h8 pl-0 mb-1">
            Payment terms
          </b-col>
          <b-col class="pl-0 h9">
            How do you want to be paid?
          </b-col>
        </b-col>
        <b-col
          v-if="['fixed fee bonus','contingency bonus'].includes(additional_terms)"
          class="pl-0 mt-2"
        >
          <b-form-group>
            <b-form-radio
              v-model="payments_frequency"
              value="1 instalment"
              class="radio-btns-text"
            >
              <label class="radio-btns-text h9">
                I want to receive payment in 1 instalment
              </label>
              <b-col
                v-if="payments_frequency === '1 instalment'"
                class="pl-0 d-inline-flex mt-1"
              >
                <b-form-select
                  v-model="oneInstalment"
                  class="h9"
                  :options="options"
                  style="max-width: 200px;"
                />
              </b-col>
            </b-form-radio>
            <b-form-radio
              v-if="additional_terms === 'contingency bonus'"
              v-model="payments_frequency"
              value="2 instalments"
              class="radio-btns-text"
            >
              <label class="radio-btns-text h9">
                I want to receive payment in 2 instalments
              </label>
              <b-col
                v-if="payments_frequency === '2 instalments'"
                class="pl-0 mt-1"
              >
                <b-col class="d-inline-flex pl-0">
                  <label class="radio-btns-text mr-3 mt-2 h9">
                    1. instalment
                  </label>
                  <b-form-input
                    v-model="twoInstalmentsPercentage1"
                    class="h9"
                    placeholder="Set %"
                    number
                    style="max-width: 60px;"
                  />
                  <label class="radio-btns-text ml-3 mr-3 mt-2 h9">
                    at
                  </label>
                  <b-form-select
                    v-model="twoInstalment1"
                    class="h9"
                    :options="options"
                    style="max-width: 200px;"
                  />
                </b-col>
                <b-col class="d-inline-flex mt-1 pl-0">
                  <label class="radio-btns-text mr-3 mt-2 h9">
                    2. instalment
                  </label>
                  <b-form-input
                    v-model="twoInstalmentsPercentage2"
                    class="h9"
                    placeholder="Set %"
                    number
                    style="max-width: 60px;"
                  />
                  <label class="radio-btns-text ml-3 mr-3 mt-2 h9">
                    at
                  </label>
                  <b-form-select
                    v-model="twoInstalment2"
                    class="h9"
                    :options="options"
                    style="max-width: 200px;"
                  />
                </b-col>
              </b-col>
            </b-form-radio>
            <b-form-radio
              v-if="additional_terms === 'contingency bonus'"
              v-model="payments_frequency"
              value="3 instalments"
              class="radio-btns-text"
            >
              <label class="radio-btns-text h9">
                I want to receive payment in 3 instalments
              </label>
              <b-col
                v-if="payments_frequency === '3 instalments'"
                class="pl-0 mt-1"
              >
                <b-col class="d-inline-flex pl-0">
                  <label class="radio-btns-text mr-3 mt-2 h9">
                    1. instalment
                  </label>
                  <b-form-input
                    v-model="threeInstalmentsPercentage1"
                    class="h9"
                    placeholder="Set %"
                    number
                    style="max-width: 60px;"
                  />
                  <label class="radio-btns-text ml-3 mr-3 mt-2 h9">
                    at
                  </label>
                  <b-form-select
                    v-model="threeInstalment1"
                    class="h9"
                    :options="options"
                    style="max-width: 200px;"
                  />
                </b-col>
                <b-col class="d-inline-flex mt-1 pl-0">
                  <label class="radio-btns-text mr-3 mt-2 h9">
                    2. instalment
                  </label>
                  <b-form-input
                    v-model="threeInstalmentsPercentage2"
                    class="h9"
                    placeholder="Set %"
                    number
                    style="max-width: 60px;"
                  />
                  <label class="radio-btns-text ml-3 mr-3 mt-2 h9">
                    at
                  </label>
                  <b-form-select
                    v-model="threeInstalment2"
                    class="h9"
                    :options="options"
                    style="max-width: 200px;"
                  />
                </b-col>
                <b-col class="d-inline-flex mt-1 pl-0">
                  <label class="radio-btns-text mr-3 mt-2 h9">
                    3. instalment
                  </label>
                  <b-form-input
                    v-model="threeInstalmentsPercentage3"
                    class="h9"
                    placeholder="Set %"
                    number
                    style="max-width: 60px;"
                  />
                  <label class="radio-btns-text ml-3 mr-3 mt-2 h9">
                    at
                  </label>
                  <b-form-select
                    v-model="threeInstalment3"
                    class="h9"
                    :options="options"
                    style="max-width: 200px;"
                  />
                </b-col>
              </b-col>
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-col> -->
    </b-col>
    <hr>
    <b-col
      class="pl-0"
      cols="8"
    >
      <b-col class="d-inline-flex h9 pl-0">
        <b-col class="text-left">
          <b class="mr-1">Total fee</b>
          <i
            v-b-tooltip.hover
            class="ri-question-line"
            title="Total fee the client will see on your proposal. Includes your fee and Relancer Service Fee"
          />
        </b-col>
        <b-col class="text-left pr-0">
          {{ currencyChanger }} {{ userFee }}/h
        </b-col>
      </b-col>
      <b-col class="d-inline-flex h9 pl-0 mt-3">
        <b-col
          class="text-left"
        >
          <b class="mr-1">Relancer Service Fee</b>
          <b-btn
            v-b-modal.explanation
            variant="text"
          >
            - Explain this
          </b-btn>
        </b-col>
        <b-col class="text-left pr-0">
          {{ currencyChanger }} {{ relancerFee }}/h
        </b-col>
      </b-col>
      <b-modal
        id="explanation"
        ok-only
        ok-title="Close"
        ok-variant="primary"
        centered
      >
        <b-card
          header="Relancer service fees"
          class="h6"
        >
          <b-row>
            <b-col class="h9 text-left">
              Relancer charges Recruiters a sliding Service Fee based on the <b>total amount billed</b> to a single client. The higher the total amount, the lower the percentage you pay to Relancer.
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-3">
            <b-col class="h9 text-left">
              <b>Total billings with the client</b>
            </b-col>
            <b-col class="h9 text-left">
              <b>Fee %</b>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="h9 text-left d-inline-flex p-0">
              <b-col>First 10,000€</b-col>
              <b-col>15%</b-col>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col class="h9 text-left d-inline-flex p-0">
              <b-col>Between 10,000 - 20,000€</b-col>
              <b-col>10%</b-col>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col class="h9 text-left d-inline-flex p-0">
              <b-col>After 20,000€</b-col>
              <b-col>5%</b-col>
            </b-col>
          </b-row>
        </b-card>
      </b-modal>
      <b-col class="d-inline-flex h9 pl-0 mt-3">
        <b-col class="text-left">
          <b class="mr-1">You get</b>
          <i
            v-b-tooltip.hover
            class="ri-question-line"
            title="The estimated amount you’ll receive after Relancer service fees"
          />
        </b-col>
        <b-col class="text-left pr-0">
          <b>{{ currencyChanger }} {{ youGet }}/h </b>
        </b-col>
      </b-col>
    </b-col>
  </b-container>
</template>

<script>
import { vuexMapper } from '@/helpers/vuexMapper'

export default {
  name: 'HourlyFeeCalc',
  data () {
    return {
      currencyOptions: [
        { value: null, text: 'Choose currency' },
        { value: '€', text: '€' },
        { value: '$', text: '$' },
        { value: '£', text: '£' },
      ],
      // additional_terms: '',
      // fixedFeeSum: null,
      // annualSalaryPercentage: null,
      // annualSalary: null,
      options: [
        { value: null, text: 'Choose payment time' },
        { value: 'prepayment', text: 'prepayment' },
        { value: 'after presenting candidates shortlist', text: 'after presenting candidates shortlist' },
        { value: 'after the candidate is hired', text: 'after the candidate is hired' },
      ],
    }
  },
  computed: {
    userFee () {
      let yourFee = 0

      if (this.hourly_rate) {
        yourFee = this.hourly_rate
      }
      return yourFee.toFixed(2)
    },
    relancerFee () {
      let relancerFee = 0
      const relancerFeePercentage = 15

      if (this.hourly_rate) {
        relancerFee = this.userFee * relancerFeePercentage / 100
      }
      return relancerFee.toFixed(2)
    },
    youGet () {
      let youGet = 0

      if (this.hourly_rate) {
        youGet = this.userFee - this.relancerFee
      }
      return youGet.toFixed(2)
    },
    currencyChanger () {
      let currency = ''
      if (this.currency === 'EUR') {
        currency = '€'
      }
      if (this.currency === 'USD') {
        currency = '$'
      }
      if (this.currency === 'GBP') {
        currency = '£'
      }
      return currency
    },
    ...vuexMapper({
      fields: [
        'currency',
        'hourly_rate',
        'payments_frequency',
        'oneInstalment',
        'twoInstalment1',
        'twoInstalment2',
        'threeInstalment1',
        'threeInstalment2',
        'threeInstalment3',
        'twoInstalmentsPercentage1',
        'twoInstalmentsPercentage2',
        'threeInstalmentsPercentage1',
        'threeInstalmentsPercentage2',
        'threeInstalmentsPercentage3',
      ],
      base: 'proposal',
      nestedObject: 'proposalForm',
      mutation: 'UPDATE_STORE',
    }),
  },
  methods: {
    // formatNumber (e) {
    //   return String(e).substring(0, 3)
    // },
  },
}
</script>

<style lang="scss" scoped>
  .radio-btns-text {
    font-family: "Jost";
    color: #39364F;
    font-size: 14px;
    font-weight: 400;
  }
</style>
