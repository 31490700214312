<template>
  <b-container class="fee-proposal mb-5 ml-3">
    <b-form @submit.prevent="submitForm(match.id)">
      <b-row>
        <b-col v-if="match">
          <ProposalOverview :match="match" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ProposalTerms />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ProposalInfo />
        </b-col>
      </b-row>
      <b-row class="float-right mt-5 mb-3">
        <b-col>
          <!-- <b-btn
            class="mr-4"
            variant="text"
          >
            Save as draft
          </b-btn> -->
          <b-btn
            class="mr-3"
            variant="ghost"
            @click="cancel"
          >
            Cancel
          </b-btn>
          <b-btn
            variant="primary"
            type="submit"
          >
            Submit proposal
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import axios from '@/helpers/axios'
import ProposalOverview from '@/components/proposal/ProposalOverview.vue'
import ProposalTerms from '@/components/proposal/ProposalTerms.vue'
import ProposalInfo from '@/components/proposal/ProposalInfo.vue'

export default {
  name: 'FeeProposal',
  components: {
    ProposalOverview,
    ProposalTerms,
    ProposalInfo,
  },
  data () {
    return {
      match: null,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters('auth', ['recruiter']),
  },
  created () {
    axios.get(`/v2/matches/${this.$route.params.id}`)
      .then(({ data }) => {
        this.match = data
      })
  },
  methods: {
    ...mapActions('proposal', ['submitForm']),
    cancel () {
      this.$router.push('/messages')
    },
  },
}
</script>

<style lang="scss" scoped>
  .fee-proposal {
    max-width: 74vw;
    .btn-text {
      color: #605D9F;
      vertical-align: baseline;
    }
  }
</style>
