<template>
  <b-row class="overview-block d-inline-block">
    <b-col class="h7 mt-4">
      Project overview
    </b-col>
    <b-col class="mt-4">
      <b-card class="overview-card">
        <b-col
          class="p-0 h8 ml-3"
          style="font-size: 18px;"
        >
          {{ match.project.title }}
        </b-col>
        <b-col class="h8 mt-4 p-0 ml-3">
          About the project
        </b-col>
        <hr class="ml-3">
        <b-col
          v-if="match.project.description"
          class="p-0 ml-3"
        >
          <p
            class="description"
            v-html="match.project.description.replaceAll('\n', '<br>')"
          />
        </b-col>
        <b-col
          v-if="match.project.services && match.project.services.length"
          class="mt-5"
        >
          <b-row>
            <b-col>
              <i class="ri-user-settings-line ri-xl pr-1" />
              <label class="data-field-heading"> Required recruiter services </label>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col>
              <b-badge
                v-for="service in match.project.services"
                :key="service.id"
                class="new-badge-dsg"
              >
                {{ service.label }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ProposalOverview',
  props: {
    match: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .overview-block {
    min-width: 74vw;
    .overview-card {
      background-color: #FFF;
      box-shadow: 0px 0px 40px rgba(216, 216, 216, 0.5);
      border-radius: 12px;
      border: none;
    }
    .description {
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #39364F;
    }
  }
</style>
