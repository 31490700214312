<template>
  <b-row class="overview-block d-inline-block">
    <b-col class="h7 title mt-4">
      Project fees
    </b-col>
    <b-col class="mt-4">
      <b-card class="terms-card">
        <b-col>
          <b-col class="h8 mt-2 mb-2 p-0">
            Pricing model
            <span class="text-primary">*</span>
          </b-col>
          <b-col class="p-0 mt-3">
            <b-form-radio
              v-model="pricing_model"
              value="hourly-fee"
              class="radio-btns-text"
              size="sm"
            >
              Hourly fee
            </b-form-radio>
            <b-form-radio
              v-model="pricing_model"
              value="fixed-fee"
              class="radio-btns-text"
              size="sm"
            >
              Fixed fee
            </b-form-radio>
            <b-form-radio
              v-model="pricing_model"
              value="contingency-fee"
              class="radio-btns-text"
              size="sm"
            >
              Contingency fee
            </b-form-radio>
          </b-col>
        </b-col>
        <b-col>
          <HourlyFeeCalc v-if="pricing_model === 'hourly-fee'" />
          <FixedFeeCalc v-if="pricing_model === 'fixed-fee'" />
          <ContingencyCalc v-if="pricing_model === 'contingency-fee'" />
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import HourlyFeeCalc from '@/components/proposal/HourlyFeeCalc.vue'
import FixedFeeCalc from '@/components/proposal/FixedFeeCalc.vue'
import ContingencyCalc from '@/components/proposal/ContingencyCalc.vue'
import { vuexMapper } from '@/helpers/vuexMapper'

export default {
  name: 'ProposalTerms',
  components: {
    HourlyFeeCalc,
    FixedFeeCalc,
    ContingencyCalc,
  },
  computed: {
    ...vuexMapper({
      fields: [
        'pricing_model',
        'currency',
        'hourly_rate',
        'fixed_fee',
        'contingency_fee',
        'annual_salary',
        'payments_frequency',
        'twoInstalmentsPercentage1',
        'twoInstalmentsPercentage2',
        'threeInstalmentsPercentage1',
        'threeInstalmentsPercentage2',
        'threeInstalmentsPercentage3',
        'oneInstalment',
        'twoInstalment1',
        'twoInstalment2',
        'threeInstalment1',
        'threeInstalment2',
        'threeInstalment3',
      ],
      base: 'proposal',
      nestedObject: 'proposalForm',
      mutation: 'UPDATE_STORE',
    }),
  },
  watch: {
    pricing_model () {
      if (this.pricing_model === 'hourly-fee') {
        this.currency = null
        this.hourly_rate = null
        this.fixed_fee = null
        this.contingency_fee = null
        this.annual_salary = null
        this.payments_frequency = null
        this.twoInstalmentsPercentage1 = null
        this.twoInstalmentsPercentage2 = null
        this.threeInstalmentsPercentage1 = null
        this.threeInstalmentsPercentage2 = null
        this.threeInstalmentsPercentage3 = null
        this.oneInstalment = null
        this.twoInstalment1 = null
        this.twoInstalment2 = null
        this.threeInstalment1 = null
        this.threeInstalment2 = null
        this.threeInstalment3 = null
      } else if (this.pricing_model === 'fixed-fee') {
        this.currency = null
        this.hourly_rate = null
        this.fixed_fee = null
        this.contingency_fee = null
        this.annual_salary = null
        this.payments_frequency = null
        this.twoInstalmentsPercentage1 = null
        this.twoInstalmentsPercentage2 = null
        this.threeInstalmentsPercentage1 = null
        this.threeInstalmentsPercentage2 = null
        this.threeInstalmentsPercentage3 = null
        this.oneInstalment = null
        this.twoInstalment1 = null
        this.twoInstalment2 = null
        this.threeInstalment1 = null
        this.threeInstalment2 = null
        this.threeInstalment3 = null
      } else if (this.pricing_model === 'contingency-fee') {
        this.currency = null
        this.hourly_rate = null
        this.fixed_fee = null
        this.contingency_fee = null
        this.annual_salary = null
        this.payments_frequency = null
        this.twoInstalmentsPercentage1 = null
        this.twoInstalmentsPercentage2 = null
        this.threeInstalmentsPercentage1 = null
        this.threeInstalmentsPercentage2 = null
        this.threeInstalmentsPercentage3 = null
        this.oneInstalment = null
        this.twoInstalment1 = null
        this.twoInstalment2 = null
        this.threeInstalment1 = null
        this.threeInstalment2 = null
        this.threeInstalment3 = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .overview-block {
    min-width: 74vw;
    .terms-card {
      background-color: #FFF;
      box-shadow: 0px 0px 40px rgba(216, 216, 216, 0.5);
      border-radius: 12px;
      border: none;
      .radio-btns-text {
        font-family: "Jost";
        color: #39364F;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
</style>
