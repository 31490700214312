<template>
  <b-row class="info-block d-inline-block">
    <b-col class="h7 title mt-4">
      Additional information
    </b-col>
    <b-col class="mt-4">
      <b-card class="info-cards">
        <b-col class="h9 mt-2 mb-2">
          Add additional terms, bonuses or any other information.
        </b-col>
        <b-col class="mt-3 mb-3">
          <b-form-textarea
            v-model="message"
            placeholder="Type your message here."
            rows="3"
          />
        </b-col>
      </b-card>
    </b-col>
    <b-col class="h7 title mt-4">
      Attachment
    </b-col>
    <b-col class="mt-4">
      <b-card class="info-cards">
        <b-col class="h9 mt-2 mb-2">
          Add a presentation or any other material(max. 1 file upload)
        </b-col>
        <b-col class="mt-3 mb-3">
          <b-form-file
            v-model="localFile"
            :state="Boolean(localFile)"
            placeholder="Drag and drop or upload a file"
            drop-placeholder="Drop file here..."
            @change="uploadFile"
          />
        </b-col>
      </b-card>
    </b-col>
    <b-col class="h7 title mt-4">
      Guarantees
    </b-col>
    <b-col class="mt-4">
      <b-card class="info-cards">
        <b-col class="h9 mt-2 mb-2">
          Add guarantee(s)
        </b-col>
        <b-row>
          <b-col class="mt-3 mb-3 d-inline-flex h9">
            <b-col
              class="d-inline-flex"
            >
              <b-form-checkbox
                v-model="guaranteeStatus"
                value="accepted"
                unchecked-value="not_accepted"
              />
              <label style="margin-top: 6px;">
                If the hired candidate’s employment is terminated within the first
              </label>
              <b-form-select
                v-model="default_guarantee"
                :disabled="guaranteeStatus === 'not_accepted'"
                :options="guarantee1Options"
                class="ml-2 mr-2"
                size="sm"
                style="max-width: fit-content;"
              />
              <label style="margin-top: 6px;">
                months of employment, a free replacement shall be provided
              </label>
            </b-col>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-btn
              class="ml-3 h9"
              variant="text"
              style="display: inline-flex;align-items: center;"
              @click="textFormGuarantee = !textFormGuarantee"
            >
              <i class="ri-add-line mr-1" />
              or add a custom guarantee
            </b-btn>
          </b-col>
        </b-row>
        <b-col
          v-if="textFormGuarantee"
          class="mt-3 mb-3"
        >
          <b-form-textarea
            v-model="custom_guarantee"
            placeholder="Add your own guarantee here."
            rows="3"
          />
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { vuexMapper } from '@/helpers/vuexMapper'
import axios from '@/helpers/axios'

export default {
  name: 'ProposalInfo',
  data () {
    return {
      localFile: [],
      guaranteeStatus: 'not_accepted',
      guarantee1Options: [
        { value: null, text: '0' },
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
      ],
      textFormGuarantee: false,
    }
  },
  computed: {
    ...vuexMapper({
      fields: [
        'message',
        'attachments',
        'default_guarantee',
        'custom_guarantee',
      ],
      base: 'proposal',
      nestedObject: 'proposalForm',
      mutation: 'UPDATE_STORE',
    }),
  },
  methods: {
    uploadFile (event) {
      const form = new FormData()
      form.set('file', event.target.files[0])
      axios.post('/v2/upload', form)
        .then(({ data }) => {
          this.attachments = []
          this.attachments.push(data)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .info-block {
    min-width: 74vw;
    .info-cards {
      background-color: #FFF;
      box-shadow: 0px 0px 40px rgba(216, 216, 216, 0.5);
      border-radius: 12px;
      border: none;
    }
  }
</style>
